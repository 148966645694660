<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Cecos y Afes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Cecos y Afes</li>
                </ol>
              </div>
            </div>
          </div>
          <!-- /.container-fluid -->
        </section>
        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12"></div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Funcionario Responsable
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="funcionario"
                          placeholder="Nombre"
                          label="nombres"
                          :options="listasForms.funcionarios"
                          @input="selectFuncionario()"
                        ></v-select>
                      </th>
                      <th>
                        Líneas Negocio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="linea_negocio"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.lineas_negocio"
                          :filterable="true"
                          multiple
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Codigo Contable
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.codigo_contable"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo De Presupuesto
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_presupuesto"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option value="1">Afes</option>
                          <option value="2">Cecos</option>
                        </select>
                      </th>
                      <th>
                        Orden Interna
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.orden_interna"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Alianza Estrategica
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.alianza_estrategica"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Descripcion</th>
                      <th>
                        Chequera
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.chequera"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="chequera in listasForms.chequeras"
                            :key="chequera.numeracion"
                            :value="chequera.numeracion"
                          >
                            {{ chequera.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Presupuesto</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="CecosYafes in CecosYafes1.data"
                      :key="CecosYafes.id"
                    >
                      <td v-if="CecosYafes.funcionario">
                        {{ CecosYafes.funcionario.nombres }}
                        {{ CecosYafes.funcionario.apellidos }}
                      </td>
                      <td v-else class="table-danger"></td>
                      <td class="text-center">
                        <div
                          v-for="lineas in CecosYafes.lineas_negocio"
                          :key="lineas.id"
                        >
                          <span class="badge bg-navy">
                            {{ lineas.nombre }}
                          </span>
                        </div>
                      </td>
                      <td>{{ CecosYafes.codigo_contable }}</td>
                      <td>
                        {{
                          CecosYafes.tipo_presupuesto == 1
                            ? "AFE"
                            : CecosYafes.tipo_presupuesto == 2
                            ? "CECO"
                            : ""
                        }}
                      </td>
                      <td>{{ CecosYafes.orden_interna }}</td>
                      <td>{{ CecosYafes.alianza_estrategica }}</td>
                      <td>{{ CecosYafes.descripcion }}</td>
                      <td>{{ CecosYafes.Chequera }}</td>
                      <td>{{ CecosYafes.nPresupuesto }}</td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <div>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              v-if="
                                $store.getters.can('admin.cecosYafes.create')
                              "
                              @click="edit(CecosYafes)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="CecosYafes1.total">
                  <p>
                    Mostrando del <b>{{ CecosYafes1.from }}</b> al
                    <b>{{ CecosYafes1.to }}</b> de un total:
                    <b>{{ CecosYafes1.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="CecosYafes1"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";

export default {
  name: "CecosYafesIndex",
  components: {
    pagination,
    vSelect,
  },
  data() {
    return {
      funcionario: {},
      linea_negocio: [],
      empresa: this.$store.getters.getUser.empresas,
      filtros: {
        codigo_contable: null,
        tipo_presupuesto: null,
        orden_interna: null,
        alianza_estrategica: null,
        funcionario: null,
        descripcion: null,
        responsable: null,
        chequera: null,
        presupuesto: null,
        lineas_negocio: null,
      },
      CecosYafes1: {},
      listasForms: {
        funcionarios: [],
        lineas_negocio: [],
        chequeras: [],
        tipo_presupuesto: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {},
  methods: {
    async getIndex(page = 1) {
      this.filtros.lineas_negocio = [];
      this.linea_negocio.forEach((linea) => {
        this.filtros.lineas_negocio.push(linea.id);
      });
      await axios
        .get("/api/admin/cecosYafes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.CecosYafes1 = response.data;
          for (let i = 0; i < this.CecosYafes1.data.length; i++) {
            let valor = this.CecosYafes1.data[i].presupuesto;

            this.CecosYafes1.data[i].nPresupuesto = this.formatCurrency(
              "en-US",
              "USD",
              2,
              valor
            );
          }
        });
    },
    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getCodigoContable() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getChequera() {
      axios.get("/api/lista/140").then((response) => {
        this.listasForms.chequeras = response.data;
      });
    },

    getFuncionarios() {
      let me = this;
      var url = "/api/admin/funcionarios/lista";
      axios
        .get(url, {
          params: {},
        })
        .then(function(response) {
          me.listasForms.funcionarios = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectFuncionario() {
      this.filtros.funcionario_id = null;
      if (this.funcionario) {
        this.filtros.funcionario_id = this.funcionario.id;
        this.getIndex();
      }
    },

    selectAlianza() {
      this.filtros.alianza_estrategica = null;
    },
    edit(CecosYafes) {
      return this.$router.push({
        name: "/Admin/CecosYafesForm",
        params: { accion: "Editar", CecosYafes: CecosYafes },
      });
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },
  },

  mounted() {
    this.getIndex();
    this.getFuncionarios();
    this.getLineasNegocio();
    this.getChequera();
  },
};
</script>
